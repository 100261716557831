import React, { useState, useCallback, useRef } from 'react'
import { debounce, isEmpty, get } from 'lodash'
import PropTypes from 'prop-types'
import { Tooltip } from 'reactstrap'
import api from '../../service/api'
import Input from '../../components/Input'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import CustomFlatFileButton from '../../components/FlatFile/FlatfileButton'
import { ReactComponent as CircularLoading } from 'src/assets/icons/circular-loading.svg'
import { connect } from 'react-redux'
import {
	getIsUserDemo,
	getUserCountries,
	getUserData,
	getUserSettings,
	getUserFeatureFlags,
} from 'src/redux/selectors/user'

import { createFullAddress, getErrorMessage, numberWithCommas } from 'src/utils'
import { useOnClickOutside } from 'src/customHooks'
import { addToast } from '../../redux/actions/toasts'
import { FLAT_FILE_MAX_RECORDS } from '../../components/FlatFile/constants'
import {
	ADDRESS_API,
	GREAT_BRITAIN_COUNTRY_CODE,
	DEMO_USER_MAX_PROJECTS_DEFAULT,
	DEMO_USER_MAX_ASSETS_PER_PROJECT_DEFAULT,
} from '../../constants'
import { MODAL_VIEWS } from '../ModalViews'
import { closeModal, setModal } from '../../redux/actions/modal'
import classNames from 'classnames'
import './styles.scss'

function AssetsProvider({
	createProject,
	fetchUserSettings,
	addAssetsToExistingProject,
	user,
	userCountries,
	isUserDemo,
	userSettings,
	userFeatureFlags,
	addToast,
	maximumAssetsToAdd,
	setModal,
	closeModal,
	isLoading,
}) {
	const dropdownEl = useRef(null)
	const inputEl = useRef(null)

	const [suggestionsSource, setSuggestionsSource] = useState('')
	const [suggestions, setSuggestions] = useState([false])
	const [suggestionsLoading, setSuggestionsLoading] = useState(false)
	const [isSuggestionsOpen, setIsSuggestionsOpen] = useState(false)
	const [isTooltipActive, setIsTooltipActive] = useState(false)
	const enableUploadPolygon = userFeatureFlags['enable-upload-polygon'] || false
	const demoUserLimits = userFeatureFlags['increase_demo_limit_projects'] || {
		MAX_RECORDS_FOR_DEMO_USER: DEMO_USER_MAX_ASSETS_PER_PROJECT_DEFAULT,
		MAX_PROJECTS_FOR_DEMO_USER: DEMO_USER_MAX_PROJECTS_DEFAULT,
	}
	const MAX_RECORDS_FOR_DEMO_USER = demoUserLimits.MAX_RECORDS_FOR_DEMO_USER
	const MAX_PROJECTS_FOR_DEMO_USER = demoUserLimits.MAX_PROJECTS_FOR_DEMO_USER

	useOnClickOutside(dropdownEl, () => {
		setIsSuggestionsOpen(false)

		if (inputEl.current) {
			inputEl.current.value = ''
		}
	})

	let assetsUploadLimitInfoElementId = 'assets-upload-limit-info'
	let flatFileMaxRecords = isUserDemo
		? MAX_RECORDS_FOR_DEMO_USER
		: FLAT_FILE_MAX_RECORDS

	if (maximumAssetsToAdd !== undefined) {
		assetsUploadLimitInfoElementId = 'assets-upload-modal-limit-info'
		flatFileMaxRecords = maximumAssetsToAdd
	}

	const loadSuggestions = useCallback(
		debounce(async (searchPattern) => {
			try {
				const data = await api.get(
					`/address-search?address_string=${searchPattern}`
				)

				let suggestions = !isEmpty(get(data, 'results')) ? data.results : []

				setSuggestionsSource(data.source)
				setSuggestions(suggestions)
				setSuggestionsLoading(false)
			} catch (error) {
				setSuggestionsLoading(false)
				addToast({
					body: getErrorMessage(error, 'Error: Could not load suggestions'),
					type: 'error',
				})
			}
		}, 400),
		[addToast]
	)

	const handlePostCodeChange = (value) => {
		if (!isSuggestionsOpen) {
			setIsSuggestionsOpen(true)
			setSuggestionsLoading(true)
		} else if (value.length === 0) {
			setIsSuggestionsOpen(false)
		}

		if (value) {
			loadSuggestions(value)
		}
	}

	const handleSuggestionClick = async (suggestedAsset) => {
		let countryCode = ''
		let assetData = {}
		if (suggestionsSource === ADDRESS_API.GOOGLE) {
			assetData = {
				place_id: suggestedAsset.place_id,
			}
			try {
				const response = await api.get(
					`/address-search/to-code?place_id=${assetData.place_id}`
				)
				countryCode = response.country_id
			} catch (error) {
				addToast({
					body: getErrorMessage(error, 'Error: Could not get country code'),
					type: 'error',
				})
				return
			}
		} else if (suggestionsSource === ADDRESS_API.IDEAL) {
			assetData = suggestedAsset
			countryCode = GREAT_BRITAIN_COUNTRY_CODE
		}

		if (!userCountries.includes(countryCode)) {
			setModal({
				viewKey: MODAL_VIEWS.INFORMATION_MODAL,
				viewProps: {
					message: (
						<span>
							Sorry! You don't currently have permission to access this country
							or region. Please contact{' '}
							<a href='mailto:help@climate-x.com'>help@climate-x.com</a> to
							request additional access for your account or organisation.
						</span>
					),
					onClose: () => {
						closeModal()
					},
				},
			})
			return
		}

		if (createProject) {
			fetchUserSettings()
			createProject(assetData, suggestionsSource)
			fetchUserSettings()
		} else if (addAssetsToExistingProject) {
			addAssetsToExistingProject(assetData, suggestionsSource)
		}

		setIsSuggestionsOpen(false)
	}

	const handleDataReceive = (assets) => {
		const { data, batchId, allData } = assets
		if (createProject) {
			fetchUserSettings()
			createProject(data, null, batchId, allData.length)
			fetchUserSettings()
		} else if (addAssetsToExistingProject) {
			addAssetsToExistingProject(data, null, batchId, allData.length)
		}
	}
	let isUploadDisabled = false
	if (isUserDemo && userSettings.total_projects >= MAX_PROJECTS_FOR_DEMO_USER) {
		isUploadDisabled = true
	}

	return (
		<div
			id='assets-provider-wrapper'
			className={classNames(
				'address-search',
				isLoading && 'address-search__project-loading pulse-fade'
			)}
			ref={dropdownEl}
		>
			<div>
				{isUploadDisabled ? (
					<div className='error'>
						You have reached the DEMO limit of {MAX_PROJECTS_FOR_DEMO_USER}{' '}
						total projects created. Please contact support if you need further
						assistance.
					</div>
				) : (
					<div>
						<div>
							<h1
								data-locator-id='heading-search-bar'
								className='mb-1 p-0 address-search__form-header'
							>
								Evaluate an asset
							</h1>
						</div>
						<div className='row align-items-bottom g-0 address-search__input-wrapper'>
							<div className='col-10 form-floating'>
								<Input
									type='text'
									className='form-control form-control-lg address-search__input col-9'
									labelClassName='address-search__label'
									list='address-search__List'
									placeholder='e.g. 81 Rivington Street...'
									label='Search an address or upload file'
									ref={inputEl}
									onChange={(e) => handlePostCodeChange(e.target.value)}
									locatorId='input-address-search-bar'
								/>
							</div>

							{isSuggestionsOpen && (
								<div className='address-search__suggestions'>
									{suggestionsLoading ? (
										<div className='address-search__loading-wrapper'>
											<CircularLoading className='address-search__loading' />
										</div>
									) : isEmpty(suggestions) ? (
										<div className='address-search__suggestions-not-found'>
											Address not found
										</div>
									) : (
										suggestions.map((suggestion, index) => {
											let fullAddress = ''
											if (suggestionsSource === ADDRESS_API.GOOGLE) {
												fullAddress = suggestion.name
											} else if (suggestionsSource === ADDRESS_API.IDEAL) {
												const {
													building_number,
													building_name,
													street_name,
													sub_building,
													postcode,
													city,
												} = suggestion

												fullAddress = createFullAddress([
													building_number,
													street_name +
														(building_name && street_name ? ',' : ''),
													building_name +
														(sub_building && building_name ? ',' : ''),
													sub_building + (sub_building && postcode ? ',' : ''),
													postcode + (postcode && city ? ',' : ''),
													city,
												])
											}

											return (
												<div
													className='address-search__suggestions-item'
													key={index}
													onClick={() => handleSuggestionClick(suggestion)}
													data-locator-id='element-address-search-item'
												>
													{fullAddress}
												</div>
											)
										})
									)}
								</div>
							)}
						</div>
					</div>
				)}
			</div>

			<div className='address-search__action-buttons'>
				{' '}
				<a
					className='address-search__template-button'
					href={
						enableUploadPolygon
							? '/templates/upload_csv_with_polygon/template.xlsx'
							: '/templates/upload_csv_without_polygon/template.xlsx'
					}
				>
					<FontAwesomeIcon
						className='fa-light fa-arrow-down'
						icon={['far', 'arrow-down']}
					/>
					Template
				</a>
				<CustomFlatFileButton
					title='Import'
					className='upload-csv'
					user={user}
					isUploadDisabled={isUploadDisabled}
					maxRecords={flatFileMaxRecords}
					allowedCountries={userCountries}
					enableUploadPolygon={enableUploadPolygon}
					text={
						<>
							<FontAwesomeIcon
								className='fa-light fa-arrow-up'
								icon={['far', 'arrow-up']}
							/>
							Upload File
						</>
					}
					onDataReceived={handleDataReceive}
				/>
				<FontAwesomeIcon
					className='address-search__upload-assets-info'
					icon={['far', 'fa-info-circle']}
					id={assetsUploadLimitInfoElementId}
				/>
				<Tooltip
					placement='top'
					isOpen={isTooltipActive}
					target={assetsUploadLimitInfoElementId}
					toggle={() => setIsTooltipActive(!isTooltipActive)}
					hideArrow={false}
				>
					{`(max ${numberWithCommas(flatFileMaxRecords)} rows)`}
				</Tooltip>
			</div>
		</div>
	)
}

AssetsProvider.propTypes = {
	createProject: PropTypes.func,
	fetchUserSettings: PropTypes.func,
	addAssetsToExistingProject: PropTypes.func,
	addToast: PropTypes.func,
	isLoading: PropTypes.bool,
}

export default connect(
	(state) => ({
		user: getUserData(state),
		userCountries: getUserCountries(state),
		isUserDemo: getIsUserDemo(state),
		userSettings: getUserSettings(state),
		userFeatureFlags: getUserFeatureFlags(state),
	}),
	{
		addToast,
		setModal,
		closeModal,
	}
)(AssetsProvider)
