import {
	createFlatfileAllowedCountriesRegex,
	createFlatfileAnyCountryRegex,
} from './utils'

// eslint-disable-next-line
// TODO: update regex to support MULTIPOLYGON and also POLYGONS with holes when customers want this feature
const polygonRegexPattern =
	'^\\s*POLYGON\\s*\\(\\s*\\((([-+]?[0-9]*\\.?[0-9]+)\\s+([-+]?[0-9]*\\.?[0-9]+)\\s*,\\s*){3,}([-+]?[0-9]*\\.?[0-9]+)\\s+([-+]?[0-9]*\\.?[0-9]+)\\s*\\)\\s*\\)\\s*$'

export const createFlatFileFieldsConfig = (
	allowedCountryNames,
	enableUploadPolygon
) => {
	const config = [
		{
			label: 'Id',
			key: 'id',
			description:
				'Id needs to be populated, must be unique and cannot contain spaces',
			validators: [
				{ validate: 'required' },
				{ validate: 'unique' },
				{
					validate: 'regex_matches',
					regex: '^(.|\n|\r){0,64}$',
					error: 'Must not be more than 64 characters in length.',
				},
			],
		},
		{
			label: 'Country',
			key: 'country',
			description: `Country name needs to be populated`,
			validators: [
				{ validate: 'required' },
				{
					validate: 'regex_matches',
					regex: '^(.|\n|\r){0,64}$',
					error: 'Must not be more than 64 characters in length.',
				},
				{
					validate: 'regex_matches',
					regex: createFlatfileAnyCountryRegex(),
					regexFlags: {
						ignoreCase: true,
					},
					error: 'Please input valid country name.',
				},
				{
					validate: 'regex_matches',
					regex: createFlatfileAllowedCountriesRegex(allowedCountryNames),
					regexFlags: {
						ignoreCase: true,
					},
					error:
						"Sorry! You don't currently have permission to access this country or region. " +
						'Please contact help@climate-x.com to ' +
						'request additional access for your account or organisation.',
				},
			],
		},
		{
			label: 'City',
			key: 'city',
			description: 'City name',
			validators: [
				{
					validate: 'regex_matches',
					regex: '^(.|\n|\r){0,512}$',
					error: 'Must not be more than 512 characters in length.',
				},
			],
		},
		{
			label: 'PostCode',
			key: 'postCode',
			description:
				'Postal Code, needs to be populated / no blanks, if not provided than lat/lng should be present, >=5 and <=7 + space',
			validators: [
				{
					validate: 'required_without_all',
					fields: [
						'latitude',
						'longitude',
						'uprn',
						...(enableUploadPolygon ? ['buildingPolygon'] : []),
					],
				},
				{
					validate: 'regex_matches',
					regex: '^(.|\n|\r){0,64}$',
					error: 'Must not be more than 64 characters in length.',
				},
			],
		},
		{
			label: 'UPRN',
			key: 'uprn',
			description:
				'UPRN needs to be populated / no blanks, if not provided than lat/lng or address should be present',
			validators: [
				{
					validate: 'required_without_all',
					fields: [
						'latitude',
						'longitude',
						'postCode',
						...(enableUploadPolygon ? ['buildingPolygon'] : []),
					],
				},
				{
					validate: 'regex_matches',
					regex: '^\\d+$',
				},
				{
					validate: 'regex_matches',
					regex: '^(.|\n|\r){0,128}$',
					error: 'Must not be more than 128 characters in length.',
				},
			],
		},
		{
			label: 'Street Name',
			key: 'street',
			description:
				'Street Name, needs to be populated / no blanks, if not provided than lat/lng should be present, string',
			validators: [
				{
					validate: 'regex_matches',
					regex: '^(.|\n|\r){0,512}$',
					error: 'Must not be more than 512 characters in length.',
				},
				{
					validate: 'required_without_all',
					fields: [
						'latitude',
						'longitude',
						'postCode',
						'uprn',
						...(enableUploadPolygon ? ['buildingPolygon'] : []),
					],
				},
			],
		},
		{
			label: 'Building Number',
			key: 'buildingNumber',
			description:
				'Building Number, needs to be populated / no blanks, if not provided than lat/lng should be present or building name, string',
			validators: [
				{
					validate: 'required_without_all',
					fields: [
						'latitude',
						'longitude',
						'uprn',
						'buildingName',
						'subBuilding',
						'street',
						...(enableUploadPolygon ? ['buildingPolygon'] : []),
					],
				},
				{
					validate: 'regex_matches',
					regex: '^(.|\n|\r){0,32}$',
					error: 'Must not be more than 32 characters in length.',
				},
			],
		},
		{
			label: 'Building Name',
			key: 'buildingName',
			description:
				'Building Name, needs to be populated / no blanks, if not provided than lat/lng should be present or building number, string',
			validators: [
				{
					validate: 'required_without_all',
					fields: [
						'latitude',
						'longitude',
						'uprn',
						'buildingNumber',
						'subBuilding',
						'street',
						...(enableUploadPolygon ? ['buildingPolygon'] : []),
					],
				},
				{
					validate: 'regex_matches',
					regex: '^(.|\n|\r){0,128}$',
					error: 'Must not be more than 128 characters in length.',
				},
			],
		},
		{
			label: 'Unit or Flat',
			key: 'subBuilding',
			description:
				'Unit or Flat, needs to be populated / no blanks, if not provided than lat/lng should be present, string',
			validators: [
				{
					validate: 'required_without_all',
					fields: [
						'latitude',
						'longitude',
						'uprn',
						'buildingNumber',
						'buildingName',
						'street',
						...(enableUploadPolygon ? ['buildingPolygon'] : []),
					],
				},
				{
					validate: 'regex_matches',
					regex: '^(.|\n|\r){0,128}$',
					error: 'Must not be more than 128 characters in length.',
				},
			],
		},
		{
			label: 'Premise Use',
			key: 'premiseUse',
			description: 'Premise Use',
			validators: [
				{
					validate: 'regex_matches',
					regex:
						'^(residential|commercial|agriculture|industrial|infrastructure|mixed_use|None)$',
					error:
						'Premise use can be one of the following values: commercial, residential, agriculture, industrial, infrastructure, mixed_use or left as Blank',
					regexFlags: {
						ignoreCase: true,
					},
				},
			],
		},
		{
			label: 'Replacement Cost',
			key: 'buildingReplacementCost',
			description: 'Building Replacement Cost',
			validators: [
				{
					validate: 'regex_matches',
					regex: '^[+-]?([0-9]*[.])?[0-9]+$',
					error: 'Must be a number or left as a Blank',
				},
			],
		},
		{
			label: 'Premise Area',
			key: 'premiseArea',
			description: 'Premise Area',
			validators: [
				{
					validate: 'regex_matches',
					regex: '^[+-]?([1-9][0-9]*([.]?[0-9]+)?|[.][0-9]*[1-9][0-9]*)$',
					error: 'Must be a number greater than 0 or left as a Blank',
				},
			],
		},
		{
			label: 'Floor Count',
			key: 'floorCount',
			description: 'Floor Count',
			validators: [
				{
					validate: 'required_with',
					fields: [
						'premiseArea',
						...(enableUploadPolygon ? ['buildingPolygon'] : []),
					],
				},
				{
					validate: 'regex_matches',
					regex: '^(0|[1-9])([0-9]*)(.0+)?$',
					error: 'Must be a whole number greater than 0 or left as a Blank',
				},
			],
		},
		{
			label: 'Structure Cost',
			key: 'structureCost',
			description: 'Total Structure Cost',
			validators: [
				{
					validate: 'regex_matches',
					regex: '^[+-]?([1-9][0-9]*([.]?[0-9]+)?|[.][0-9]*[1-9][0-9]*)$',
					error: 'Must be a number greater than 0 or left as a Blank',
				},
			],
		},
		{
			label: 'Structure Category',
			key: 'structureCategory',
			description: 'Structure Category',
			validators: [
				{
					validate: 'regex_matches',
					regex: '^(C_2|C_3|C_4|C_5|None)$',
					error:
						'Structure Category can be one of the following values: C_2, C_3, C_4, C_5 or left as Blank',
					regexFlags: {
						ignoreCase: true,
					},
				},
			],
		},
		{
			label: 'Age Category',
			key: 'ageCategory',
			description: 'Age Category',
			validators: [
				{
					validate: 'regex_matches',
					regex:
						'^(pre-1919|1919-1944|1945-1964|1965-1974|1975-1985|post-1985|None)$',
					error:
						'Age Category can be one of the following values: pre-1919, 1919-1944, 1945-1964, 1965-1974, 1975-1985, post-1985 or left as Blank',
					regexFlags: {
						ignoreCase: true,
					},
				},
			],
		},
		{
			label: 'Premise Type',
			key: 'premiseType',
			description: 'Premise Type',
			validators: [
				{
					validate: 'regex_matches',
					regex:
						'^(DATA_CENTER|EDUCATION|HEALTHCARE|HIGH_ST|HOTEL|OFFICE|PUBLIC|RELIGION|RESTAURANT|SHOPPING|RECREATIONAL|PARKING|OTHER|INDUSTRIAL|WAREHOUSE|ENERGY|MIXED_USE|MULTI_RES|SINGLE_RES|AGRICULTURE|TRANSPORT|None)$',
					error:
						'Premise Type can be one of the following values: DATA_CENTER, EDUCATION, HEALTHCARE, HIGH_ST, HOTEL, OFFICE, PUBLIC, RELIGION, RESTAURANT, SHOPPING, RECREATIONAL, PARKING, OTHER, INDUSTRIAL, WAREHOUSE, ENERGY, MIXED_USE, MULTI_RES, SINGLE_RES, AGRICULTURE, TRANSPORT or left as Blank',
					regexFlags: {
						ignoreCase: true,
					},
				},
			],
		},
		{
			label: 'Latitude',
			key: 'latitude',
			description: 'Float/Double',
			validators: [
				{
					validate: 'required_without_all',
					fields: [
						'country',
						'city',
						'street',
						'postCode',
						'uprn',
						...(enableUploadPolygon ? ['buildingPolygon'] : []),
					],
				},
				{
					validate: 'required_with',
					fields: ['longitude'],
				},
				{
					validate: 'regex_matches',
					regex:
						'^(\\+|-)?(?:90(?:(?:\\.0{1,30})?)|(?:[0-9]|[1-8][0-9])(?:(?:\\.[0-9]{1,30})?))$',
				},
			],
		},
		{
			label: 'Longitude',
			key: 'longitude',
			description: 'Float/Double',
			validators: [
				{
					validate: 'required_without_all',
					fields: [
						'country',
						'city',
						'street',
						'postCode',
						'uprn',
						...(enableUploadPolygon ? ['buildingPolygon'] : []),
					],
				},
				{
					validate: 'required_with',
					fields: ['latitude'],
				},
				{
					validate: 'regex_matches',
					regex:
						'^(\\+|-)?(?:180(?:(?:\\.0{1,30})?)|(?:[0-9]|[1-9][0-9]|[1][0-7][0-9])(?:(?:\\.[0-9]{1,30})?))$',
				},
			],
		},
	]
	if (enableUploadPolygon) {
		config.push({
			label: 'Building Polygon',
			key: 'buildingPolygon',
			description: 'Building Polygon',
			validators: [
				{
					validate: 'required_without_all',
					fields: [
						'country',
						'city',
						'street',
						'postCode',
						'uprn',
						'longitude',
						'latitude',
						'premiseArea',
					],
				},
				{
					validate: 'regex_matches',
					regex: polygonRegexPattern,
					error:
						'Building Polygon needs to be a WKT string e.g: POLYGON ((0 0, 4 0, 4 4, 0 4, 0 0)) with a minimum of 4 coordinates and should be closed (the first and last coordinates should be the same) with an area <= 10,000,000 sqm. The points in the Polygon can be integers or floats (decimals).',
					regexFlags: {
						ignoreCase: true,
					},
				},
			],
		})
	}

	return config
}
