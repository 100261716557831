import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { FlatfileButton } from '@flatfile/react'
import { FLAT_FILE_USER_ID, FLAT_FILE_LICENSE_KEY } from './constants.js'
import { generateConfig } from './utils'
import './styles.scss'

const CustomFlatFileButton = ({
	onDataReceived,
	text,
	title,
	className,
	user,
	isUploadDisabled,
	maxRecords,
	allowedCountries,
	enableUploadPolygon,
}) => {
	const config = useMemo(() => {
		return generateConfig(
			title,
			maxRecords,
			allowedCountries,
			enableUploadPolygon
		)
	}, [title, maxRecords, allowedCountries])
	return (
		<FlatfileButton
			data-locator-id='button-upload-csv'
			disabled={isUploadDisabled}
			className={classnames('flat-file-button', className)}
			licenseKey={FLAT_FILE_LICENSE_KEY}
			customer={{ userId: user?.sub || FLAT_FILE_USER_ID }}
			settings={config}
			onData={async (results) => {
				onDataReceived(results)
				return 'Done!'
			}}
			fieldHooks={{
				uprn: (values) => {
					return values.map(([value, index]) => {
						if (
							value &&
							isFinite(Number(value)) &&
							Number(value).toString() !== value
						) {
							value = Number(value).toString()
						}

						return [
							{
								value,
							},
							index,
						]
					})
				},
			}}
		>
			{text}
		</FlatfileButton>
	)
}

CustomFlatFileButton.propTypes = {
	onDataReceived: PropTypes.func,
	title: PropTypes.string,
	text: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
	allowedCountries: PropTypes.arrayOf(PropTypes.string),
}

export default CustomFlatFileButton
