import api from '../../service/api'
import { getErrorMessage } from 'src/utils'
import { getAdaptations } from '../selectors/adaptations'
import { getProjectFilters } from '../selectors/projectFilters'
import { addToast } from './toasts'
import { closeProjectCompare } from './projectCompare'
import { clearPdfExportData } from './assets'

export const SELECT_ADAPTATION_CONFIG_DISPLAY = 'selectAdaptationConfigDisplay'
export function selectAdaptationConfigDisplay(isSelected) {
	return async (dispatch) => {
		dispatch(closeProjectCompare())
		dispatch(updateAdaptationConfigDisplay(isSelected))
	}
}

export function updateAdaptationConfigDisplay(isSelected) {
	return { type: SELECT_ADAPTATION_CONFIG_DISPLAY, payload: isSelected }
}

export const CLEAR_ADAPT_DATA = 'clearAdaptData'
export function clearAdaptData() {
	return { type: CLEAR_ADAPT_DATA }
}

export const SET_ADAPT_SIDEBAR_LOADING_STATE = 'setAdaptSidebarLoadingState'
export function setAdaptSidebarLoadingState(loading) {
	return {
		type: SET_ADAPT_SIDEBAR_LOADING_STATE,
		payload: loading,
	}
}
export const SET_ADAPT_ROLLUP_LOADING_STATE = 'setAdaptRollupLoadingState'
export function setAdaptRollupLoadingState(loading) {
	return {
		type: SET_ADAPT_ROLLUP_LOADING_STATE,
		payload: loading,
	}
}

export const SAVE_ADAPTIVE_CAPACITY = 'saveAdaptiveCapacity'
export function updateSaveAdaptiveCapacity() {
	return { type: SAVE_ADAPTIVE_CAPACITY, payload: {} }
}
export function saveAdaptiveCapacity() {
	return async (dispatch, getState) => {
		dispatch(setAdaptSidebarLoadingState(true)) // Start loading
		dispatch(setAdaptRollupLoadingState(true)) // Start loading

		try {
			const allAdaptations = getAdaptations(getState())
			const activeAssetId = allAdaptations.current_asset.id
			const adaptation_options =
				allAdaptations.asset_adaptations[`asset_${activeAssetId}`]
					.adaptation_options

			// TODO: Should not be .default. Should be value.
			const adaptive_capacity_options = {
				cap_included:
					adaptation_options.adaptive_capacity_options.cap_included.default,
				estimated_government_charge_or_tax:
					adaptation_options.adaptive_capacity_options
						.estimated_government_charge_or_tax.default,
				financial_capacity:
					adaptation_options.adaptive_capacity_options.financial_capacity
						.default,
				organizational_capability:
					adaptation_options.adaptive_capacity_options.organizational_capability
						.default,
				technical_capacity:
					adaptation_options.adaptive_capacity_options.technical_capacity
						.default,
			}

			const payload = {
				building_vulnerabilities: {},
				adaptation_options: {
					adaptive_capacity_options: adaptive_capacity_options,
					indirect_measures: {},
					direct_measures: {},
				},
			}

			await api.patch(`/asset-adaptations/${activeAssetId}/adaptations`, {
				asset_id: activeAssetId,
				data: payload,
			})
			dispatch(updateSaveAdaptiveCapacity())
			dispatch(clearPdfExportData())
			dispatch(fetchAdaptationLossesByAssetId(activeAssetId))
		} catch (error) {
			console.error('Failed to save adaptive capacities:', error)
		} finally {
			dispatch(setAdaptSidebarLoadingState(false)) // End loading regardless of success or failure
		}
	}
}

export const UPDATE_ADAPTIVE_CAPACITY = 'updateAdaptiveCapacity'
export function updateAdaptiveCapacity(key, value) {
	return { type: SET_ADAPTIVE_CAPACITY, payload: { key, value } }
}

export const SET_ADAPTIVE_CAPACITY = 'setAdaptiveCapacity'
export function setAdaptiveCapacity(key, value) {
	return async (dispatch, getState) => {
		const mappedValue = mapValues(value)
		dispatch(updateAdaptiveCapacity(key, mappedValue))
	}
}

const mapValues = (value, nullHandle = 'Null') => {
	const stringToType = {
		true: true,
		false: false,
		None: nullHandle,
	}

	if (value in stringToType) {
		return stringToType[value]
	}

	const isNumeric = (value) => /^-?\d+(\.\d+)?$/.test(value)

	if (isNumeric(value)) {
		return parseFloat(value)
	}

	return value
}

export const SAVE_EXPOSURE_SENSITIVITY = 'saveExposureSensitivity'
export function updateSaveExposureSensitivity() {
	return { type: SAVE_EXPOSURE_SENSITIVITY, payload: {} }
}

export function saveExposureSensitivity() {
	return async (dispatch, getState) => {
		dispatch(setAdaptSidebarLoadingState(true)) // Start loading
		dispatch(setAdaptRollupLoadingState(true)) // Start loading but this will be stopped in fetchAdaptationLossesByAssetId

		try {
			const allAdaptations = getAdaptations(getState())
			const activeAssetId = allAdaptations.current_asset.id
			const vulnerabilities =
				allAdaptations.asset_adaptations[`asset_${activeAssetId}`]
					.building_vulnerabilities

			/* this is required to prevent breakage when patching exposures only needed to add 
			area, occupants, floor_count and perimeter to stop it blowing up but added all so first time around 
			assets table gets populated with all BVs */

			const defaultBuildingVulnerabilities = {
				premise_area: vulnerabilities.premise_area,
				number_of_occupants: vulnerabilities.number_of_occupants,
				floor_count: vulnerabilities.floor_count,
				building_perimeter: vulnerabilities.building_perimeter,
				building_length: vulnerabilities.building_length,
				building_width: vulnerabilities.building_width,
				age_category: vulnerabilities.age_category.value,
				refurbished_year: vulnerabilities.refurbished_year,
				premise_type: vulnerabilities.premise_type.value,
				use: vulnerabilities.use.value,
				roof_type: vulnerabilities.roof_type.value,
				wall_type: vulnerabilities.wall_type.value,
				roof_shape: vulnerabilities.roof_shape.value,
				num_basement_floors: vulnerabilities.num_basement_floors.value,
				outdoor_space: vulnerabilities.outdoor_space.value,
				fortified_foundations: vulnerabilities.fortified_foundations.value,
			}

			const payload = {
				building_vulnerabilities: defaultBuildingVulnerabilities,
				adaptation_options: {
					adaptive_capacity_options: {},
					indirect_measures: {},
					direct_measures: {},
				},
			}

			await api.patch(`/asset-adaptations/${activeAssetId}/adaptations`, {
				asset_id: activeAssetId,
				data: payload,
			})
			dispatch(updateSaveExposureSensitivity())
			dispatch(clearPdfExportData())
			dispatch(fetchAdaptationLossesByAssetId(activeAssetId))
		} catch (error) {
			console.error('Failed to save exposure sensitivity:', error)
		} finally {
			dispatch(setAdaptSidebarLoadingState(false)) // End loading regardless of success or failure
		}
	}
}

export const UPDATE_EXPOSURE_SENSITIVITY = 'updateExposureSensitivity'
export function updateExposureSensitivity(key, value, isSelect) {
	return { type: SET_EXPOSURE_SENSITIVITY, payload: { key, value, isSelect } }
}

export const SET_EXPOSURE_SENSITIVITY = 'setExposureSensitivity'
export function setExposureSensitivity(key, value, isSelect = false) {
	return async (dispatch, getState) => {
		const mappedValue = mapValues(value, null)
		dispatch(updateExposureSensitivity(key, mappedValue, isSelect))
	}
}

export const UPDATE_INDIRECT_MEASURE = 'updateIndirectMeasure'
export function updateIndirectMeasure(key, value) {
	return { type: SET_INDIRECT_MEASURE, payload: { key, value } }
}

export const SET_INDIRECT_MEASURE = 'setIndirectMeasure'
export function setIndirectMeasure(key, value) {
	return async (dispatch, getState) => {
		try {
			const allAdaptations = getAdaptations(getState())
			const activeAssetId = allAdaptations.current_asset.id
			const payload = {
				building_vulnerabilities: {},
				adaptation_options: {
					adaptive_capacity_options: {},
					indirect_measures: { [key]: value },
					direct_measures: {},
				},
			}
			dispatch(updateIndirectMeasure(key, value))
			await api.patch(`/asset-adaptations/${activeAssetId}/adaptations`, {
				asset_id: activeAssetId,
				data: payload,
			})
			dispatch(clearPdfExportData())
			dispatch(fetchAdaptationLossesByAssetId(activeAssetId))
		} catch {}
	}
}

export const UPDATE_DIRECT_MEASURE = 'updateDirectMeasure'
export function updateDirectMeasure(key, value) {
	return { type: SET_DIRECT_MEASURE, payload: { key, value } }
}

export const SET_DIRECT_MEASURE = 'setDirectMeasure'
export function setDirectMeasure(key, value) {
	return async (dispatch, getState) => {
		try {
			const allAdaptations = getAdaptations(getState())
			const activeAssetId = allAdaptations.current_asset.id
			const payload = {
				building_vulnerabilities: {},
				adaptation_options: {
					adaptive_capacity_options: {},
					indirect_measures: {},
					direct_measures: { [key]: value },
				},
			}
			dispatch(updateDirectMeasure(key, value))
			await api.patch(`/asset-adaptations/${activeAssetId}/adaptations`, {
				asset_id: activeAssetId,
				data: payload,
			})
			dispatch(clearPdfExportData())
			dispatch(fetchAdaptationLossesByAssetId(activeAssetId))
		} catch {}
	}
}

export const ADD_ADAPTATIONS = 'addAdaptations'
export function addAdaptations(adaptations) {
	return { type: ADD_ADAPTATIONS, payload: adaptations }
}

export const ADD_ADAPTATION_RESULTS = 'addAdaptationResults'
export function addAdaptationResults(adaptationResults) {
	return { type: ADD_ADAPTATION_RESULTS, payload: adaptationResults }
}
export const ADD_ADAPTATION_DIRECT_MEASURE_RESULTS =
	'addAdaptationDirectMeasureResults'
export function addAdaptationDirectMeasureResults(
	adaptationDirectMeasureResults
) {
	return {
		type: ADD_ADAPTATION_DIRECT_MEASURE_RESULTS,
		payload: adaptationDirectMeasureResults,
	}
}
export const ADD_POTENTIAL_ADAPTATION_DIRECT_MEASURE_RESULTS =
	'addPotentialAdaptationDirectMeasureResults'
export function addPotentialAdaptationDirectMeasureResults(
	potentialAdaptationDirectMeasureResults
) {
	return {
		type: ADD_POTENTIAL_ADAPTATION_DIRECT_MEASURE_RESULTS,
		payload: potentialAdaptationDirectMeasureResults,
	}
}
export const SET_CURRENT_ASSET = 'setCurrentAsset'
export function setCurrentAsset(asset) {
	return { type: SET_CURRENT_ASSET, payload: asset }
}
export const SET_ADAPTATION_OPTIONS = 'setAdaptationOptions'
export function setAdaptationOptions(options) {
	return { type: SET_ADAPTATION_OPTIONS, payload: options }
}
export const FETCH_ADAPTATION_LOSSES_BY_ASSET_ID =
	'fetchAdaptationLossesByAssetId'
export const fetchAdaptationLossesByAssetId = (
	assetId,
	scenarioValue = null,
	defended = null
) => {
	const translateScenarioStr = (scenarioValue) => {
		const scenario = scenarioValue.split('_')[2]
		return scenario
	}
	return async (dispatch, getState) => {
		dispatch(setAdaptRollupLoadingState(true))
		dispatch(setAdaptSidebarLoadingState(true))

		try {
			if (scenarioValue === null && defended === null) {
				const projectFilters = getProjectFilters(getState())
				scenarioValue = projectFilters.data.scenario
				defended = projectFilters.data.defended
			}

			const adaptation_potential_url = `/asset-adaptations/${assetId}/adaptation-potential`
			const scenario = translateScenarioStr(scenarioValue)
			const adaptation_potential_payload = {
				asset_id: assetId,
				options: {
					defended: defended,
					scenario: scenario,
				},
			}
			const adaptation_results_url = `/asset-adaptations/${assetId}/adaptation-results`
			const adaptation_results_payload = {
				asset_id: assetId,
				options: {
					defended: defended,
					scenario: scenario,
				},
			}

			let results = await Promise.all([
				api.post(adaptation_potential_url, adaptation_potential_payload),
				api.post(adaptation_results_url, adaptation_results_payload),
			])

			let adaptation_potential_data = results[0]
			let adaptation_results_data = results[1]

			let sidebar_table = {}
			for (
				let i = 0;
				i < adaptation_potential_data.adaptation_wise_results.length;
				i++
			) {
				sidebar_table[
					adaptation_potential_data.adaptation_wise_results[
						i
					].ref_adaptation_id.toLowerCase()
				] = adaptation_potential_data.adaptation_wise_results[i]
			}

			// // Now we overwrite with actual results data
			// for(let i =0; i<adaptation_results_data.adaptation_wise_results.length; i++) {
			// 	let o = adaptation_results_data.adaptation_wise_results[i]
			// 	o["result_type"] = "actual"
			// 	sidebar_table[adaptation_results_data.adaptation_wise_results[i].ref_adaptation_id.toLowerCase()] = adaptation_results_data.adaptation_wise_results[i]
			// }
			dispatch(
				addAdaptationDirectMeasureResults({
					assetId: assetId,
					data: sidebar_table,
				})
			)
			dispatch(
				addAdaptationResults({
					assetId: assetId,
					data: adaptation_results_data,
				})
			)
		} catch (error) {
			dispatch(
				addToast({
					body: getErrorMessage(
						error,
						'Error: Assets could not be loaded fetchAdaptationLossesByAssetId'
					),
					type: 'error',
				})
			)
		} finally {
			dispatch(setAdaptRollupLoadingState(false))
			dispatch(setAdaptSidebarLoadingState(false))
		}
	}
}

export const FETCH_ADAPTATIONS_BY_ASSET_ID = 'fetchAdaptationsByAssetId'
export const fetchAdaptationsByAssetId = (asset) => {
	return async (dispatch, getState) => {
		dispatch(closeProjectCompare())
		dispatch(setAdaptSidebarLoadingState(true))

		const assetId = asset?.id

		const adaptations = getAdaptations(getState())

		const includesAssets = (assetAdaptationsList, asset_id) => {
			return assetAdaptationsList?.[`asset_${asset_id}`]
				? assetAdaptationsList?.[`asset_${asset_id}`]
				: undefined
		}

		try {
			let data = includesAssets(adaptations.asset_adaptations, assetId)

			if (!data) {
				const refAdaptationsJob = api.get(`/ref/asset-adaptations`)
				const refBuildingVulnerabilitiesJob = api.get(
					`/ref/building-vulnerabilities`
				)

				const assetAdaptationsJob = api.get(
					`/asset-adaptations/${assetId}/adaptations`
				)

				let results = await Promise.all([
					refAdaptationsJob,
					refBuildingVulnerabilitiesJob,
					assetAdaptationsJob,
				])
				const refAdaptations = results[0]
				const refBuildingVulnerabilities = results[1]

				// TODO: This is the data defined outside
				data = results[2]

				// Replacing refAdaptations defaults with actual values
				Object.keys(refAdaptations).forEach((category) => {
					const adaptationOptions = data.adaptation_options[category] || {}

					Object.keys(adaptationOptions).forEach((optionKey) => {
						const value = adaptationOptions[optionKey]
						if (
							refAdaptations[category] &&
							refAdaptations[category].hasOwnProperty(optionKey)
						) {
							refAdaptations[category][optionKey].default = value
						}
					})
				})

				// Adding select BV possible options to refAdaptations and data
				Object.keys(refBuildingVulnerabilities).forEach((key) => {
					if (data.building_vulnerabilities.hasOwnProperty(key)) {
						data.building_vulnerabilities[key] = {
							value: data.building_vulnerabilities[key],
							options: refBuildingVulnerabilities[key],
						}
					}
				})

				// want to get all data in both
				data.adaptation_options = { ...refAdaptations }
				refAdaptations.building_vulnerabilities = {
					...data.building_vulnerabilities,
				}

				//FIXME: Why we push two actions and set same data, actions (setAdaptationOptions, addAdaptations) ?
				dispatch(
					setAdaptationOptions({
						refAdaptations: refAdaptations,
						assetId: assetId,
					})
				)
			}

			dispatch(
				addAdaptations({
					assetId: assetId,
					data,
				})
			)

			dispatch(
				setCurrentAsset({
					asset: asset,
				})
			)

			dispatch(fetchAdaptationLossesByAssetId(assetId))
		} catch (error) {
			dispatch(
				addToast({
					body: getErrorMessage(
						error,
						'Error: Asset Adaptations could not be loaded'
					),
					type: 'error',
				})
			)
		} finally {
			dispatch(setAdaptSidebarLoadingState(false))
		}
	}
}
